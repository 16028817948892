import EventEmitter from './helpers.EventEmitter.ts';

class GlobalMessenger {
    private _eventEmitter: EventEmitter<{'message': any}>;

    get key() { return 'o365-global-messenger' }

    constructor() {
        this._eventEmitter = new EventEmitter();
        window.self.addEventListener('message', this._onMessage.bind(this));
    }

    /**
     * Listen for messages from parent or iframe contexts posted through GlobalMessenger
     * @paramt pListener listener callback function
     */
    on<T>(pListener: ListenerFunction<T> ) {
        return this._eventEmitter.on('message', pListener);
    }

    off<T>(pListener: ListenerFunction<T>) {
        this._eventEmitter.off('message', pListener);
    }

    removeAllListeners() {
        this._eventEmitter.removeAllListeners();
    }

    /**
     * Send a message to a parent window's GlobalMessenger instance
     * @param pPayload data to send (can't have circular refrences)
     */
    post<T>(pPayload: T) {
        if (window.parent) {
            const message = this.payloadToMessage(pPayload);
            window.parent.postMessage(message);

        }
    }

    /** Interanl message listnerer on the current window context */
    private _onMessage(event: MessageEvent) {
        try {
            const payload: MessagePayload<any> = JSON.parse(event.data);
            if (payload.meta.key !== this.key) { return; }
            this._eventEmitter.emit('message', payload.message, payload.meta);
        } catch (ex) {
            // fail silently
        }
    }

    /** Convert any payload to a message that can be caught by a GlobalMessenger instance */
    payloadToMessage<T>(pPayload: T) {
        const payload = {
            message: pPayload,
            meta: {
                key: this.key,
                broadcaster: window.location.href
            }
        };
        const message = JSON.stringify(payload);
        return message;
    }
}

type ListenerFunction<T> = (pMessage: T, pMeta?: MessagePayload<T>['meta']) => void;
export type MessageType = undefined | 'dataobject';
export type MessagePayload<T> = { 
    message: T;
    meta: {
        key: string;
        broadcaster: string;
    }
 }

const globalMessenger = new GlobalMessenger();

export default globalMessenger;